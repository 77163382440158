<template>
  <v-container id="reset-password-confirm-view" class="fill-height" tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="750"
            rounded
            class="mx-auto"
            color="accent"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <v-list-item-title class="text-h3">
                  <strong class="mr-1 white--text font-weight-black"
                    >{{ $t('signin.change.your.password') }}</strong
                  >
                </v-list-item-title>
              </div>
            </template>

            <v-form
              ref="form"
              autocomplete="on"
              v-model="valid"
              @submit.prevent="changePassword"
            >
              <v-card-text class="text-center">
                <div class="text-center font-weight-light"></div>

                <v-text-field
                  class="mt-10"
                  id="new-password"
                  v-model="newPassword1"
                  autocomplete="new-password"
                  color="secondary"
                  type="password"
                  :placeholder="$t('signin.new.password')"
                  prepend-icon="mdi-lock-outline"
                />

                <v-text-field
                  class="mb-8"
                  color="secondary"
                  v-model="newPassword2"
                  :placeholder="$t('signin.new.password.confirm')"
                  prepend-icon="mdi-lock-outline"
                  autocomplete="new-password"
                  type="password"
                  v-on:keyup.enter="resetPassword"
                />

                <v-btn color="accent" rounded text large type="submit">
                  {{ $t("signin.change.your.password") }}
                </v-btn>
              </v-card-text>
            </v-form>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { changePassword } from '@/services/auth.service'

import vue from "vue"
// import router from '@/router'
export default {
  name: "PasswordResetConfirm",

  data: () => ({
    socials: [
      {
        href: "#",
        icon: "mdi-facebook"
      },
      {
        href: "#",
        icon: "mdi-twitter"
      },
      {
        href: "#",
        icon: "mdi-github"
      }
    ],
    valid: true,
    newPassword1: null,
    newPassword2: null
  }),
  beforeMount() {
  },
  methods: {
    changePassword() {
      if (this.$refs.form.validate()) {
        changePassword(this.newPassword1, this.newPassword2, this.$route.params.uid, this.$route.params.token)
          .then(response => {
            vue.$log.info("log in success", response)
            this.$router.push({ name: "Interventions" })
          })
          .catch(error => {
            console.log('error :>> ', error.response);
            const data = error.response?.data
            if (data == null) {
              return;
            }
            if (data.token) {
              this.$dialog.notify.error("Link jest już nieważny", {
                timeout: 10000
              })
            } else if (data.new_password2)
            for (const message of data.new_password2) {
              this.$dialog.notify.error(message, {
                timeout: 10000
              })
            }
            vue.$log.error(`Incorect login/password ${error}`)
            // this.$notify({
            //   group: 'foo',
            //   type: 'error',
            //   ignoreDuplicates: true,
            //   max: 1,
            //   position: ['center', 'bottom'],
            //   title: 'Incorrect login/password',
            //   text: `${error}`
            // })
          })
      }
      return false
    }
    // loginMock: function () {
    //
    //   this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
    //     this.$router.push('/')
    //   })
    // }
  }
}
</script>
